import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Container from '../components/Container'
import Section from '../components/Section'
import { ContactList, Icon } from '../components/ContactList'

import gitlab from '../components/ContactList/icons/gitlab.svg'
import mail from '../components/ContactList/icons/mail.svg'

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <Container>


      <Section>

	<h2>Meepraten en direct product owners / developers benaderen:  </h2>

        <ContactList>
          <ContactList.Item>
            <a href="mailto:vip@vng.nl"> team Verbeteren Informatie Positie. @ VNG. </a>
          </ContactList.Item>
        </ContactList>

      </Section>

      <Section>
        <h2>Data vragen over Common Data Factory?</h2>

        <p>Stuur data expert Stephan een e-mail.</p>

        <ContactList>
          <ContactList.Item>
            <Icon src={gitlab} />
            <a href="https://www.gitlab.com/commondatafactory/">gitlab.com</a>
          </ContactList.Item>
          <ContactList.Item>
            <Icon src={mail} />
            <a href="mailto:stephan@preeker.net">Stephan Preeker (Software Engineer)</a>
          </ContactList.Item>
        </ContactList>
      </Section>

      <Section>
        <h2>Vragen over de viewers kaarten?</h2>
        <p>Maak een issue aan op GitLab of stuur Niene onze kaart expert een e-mail.</p>

        <ContactList>
          <ContactList.Item>
          </ContactList.Item>
          <ContactList.Item>
            <Icon src={mail} />
            <a href="mailto:niene@this-way.nl">Niene Boeijen (Cartograaf) </a>
          </ContactList.Item>
        </ContactList>
      </Section>

      <Section>
        <h2>Vragen over energie / de transitievisie warmte / DOOK </h2>
        <p>Maak een issue aan op GitLab of stuur ons een e-mail.</p>

        <ContactList>
          <ContactList.Item>
            <Icon src={mail} />
            <a href="mailto:vip@vng.nl"> vip@vng.nl (Energietransitie) </a>
          </ContactList.Item>
        </ContactList>

      </Section>

      <Section>
        <h2>Data wensen / Data leveringen</h2>
        <p>Maak een issue aan op GitLab of stuur ons een e-mail.</p>

        <ContactList>
          <ContactList.Item>
            <Icon src={mail} />
            <a href="mailto:vip@vng.nl">Verbeteren Informatie Positie VNG</a>
          </ContactList.Item>
        </ContactList>
      </Section>

    </Container>
  </Layout>
)

export default ContactPage
